import * as React from "react"
import ReactPlayer from 'react-player'
const UbersuggestSection1 = (props) => {
    return (
        <section className="section-1">
            <div className="container">
                <div className="video-block">
                    <ReactPlayer 
                        url={props.videoUrl} 
                        width='100%' 
                        height="100%"
                        loop={true}
                        playing={true}
                        muted={true}
                        playsinline={true}
                    />
                </div> 
            </div>
        </section> 
    )
}

export default UbersuggestSection1;