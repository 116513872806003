import * as React from "react"

const UbersuggestHeroSection = (props) => {
    return (
        <section className="page-header-section case-study-hero-section ubersuggest-hero-section p-b-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-17">
                        <p className="small section-description">{props.preTitle}</p>
                        <h1 className="h1 section-title extra-spacing">{props.mainTitle}</h1>  
                    </div>
                    <div className="col-lg-7"></div>
                </div>
                <div className="widgets">
                    <div className="widget-image-block">
                        <img 
                            src={props.image21x?.sourceUrl}
                            srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                            width={props.image21x?.width}
                            alt={props.image21x?.altText}
                        />
                    </div>
                </div>
            </div>
            <div className="image-block">
                <img
                    src={props.image1x?.sourceUrl}
                    srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                    width={props.image1x?.width}
                    alt={props.image1x?.altText}
                />
            </div>
        </section> 
    )
}

export default UbersuggestHeroSection