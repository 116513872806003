import * as React from "react"
import parse from 'html-react-parser';
import shortid from  "shortid";

const ChallengesSection = (props) => {
    return (
        <section className="case-study-challenges-section ">
        {/* p-t-full */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="company-info-items">
                            {props.companyInfoList?.map((object, i) => {
                                return (
                                    <div className="item" key={shortid.generate()}>
                                        <p className="p section-title semibold primary-black">{parse(object.title)}</p>
                                        <p className="p section-description">{parse(object.description)}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-15">
                        <div className="company-detail-items">
                            {props.challengesList?.map((object, i) => {
                                return (
                                    <div className="item" key={shortid.generate()}>
                                        <h1 className="h1 section-title">{parse(object.title)}</h1>
                                        <p className="section-description">{parse(object.description)}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChallengesSection