import * as React from "react"

const UbersuggestSection4 = (props) => {
    return (
        <section className="section-4">
            <div className="container">
                <div className="section-heading">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-18">
                            <h2 className="h1 section-title normal-spacing">{props.mainTitle}</h2>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
                <div className="image-block-1">
                    <div className="image-block">
                        <img
                            src={props.image1x?.sourceUrl}
                            srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                            width={props.image1x?.width}
                            alt={props.image1x?.altText}
                        />
                    </div>  
                </div>
            </div>
        </section> 
    )
}

export default UbersuggestSection4;