import * as React from "react"
import ReactPlayer from 'react-player'
const UbersuggestSection3 = (props) => {
    return (
        <section className="section-3">
            <div className="container">
                <div className="section-heading">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-16">
                            <h2 className="h1 section-title normal-spacing">{props.mainTitle}</h2>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-5"></div>
                    </div>
                </div>
                <div className="video-block">
                    <ReactPlayer 
                        url={props.videoUrl} 
                        width='100%' 
                        height="100%"
                        loop={true}
                        playing={true}
                        muted={true}
                        playsinline={true}
                    />
                </div> 
            </div>
        </section> 
    )
}

export default UbersuggestSection3;