import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';

import Layout from "../../components/layout"
import Seo from "../../components/seo"

//sections
import UbersuggestHeroSection from '../sections/caseStudies/uberSuggest/UbersuggestHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import UbersuggestSection1 from '../sections/caseStudies/uberSuggest/UbersuggestSection1';
import UbersuggestSection2 from '../sections/caseStudies/uberSuggest/UbersuggestSection2';
import UbersuggestSection3 from '../sections/caseStudies/uberSuggest/UbersuggestSection3';
import UbersuggestSection4 from '../sections/caseStudies/uberSuggest/UbersuggestSection4';
import UbersuggestSection5 from '../sections/caseStudies/uberSuggest/UbersuggestSection5';
import UbersuggestSection6 from '../sections/caseStudies/uberSuggest/UberSuggestSection6';
import UbersuggestSection7 from '../sections/caseStudies/uberSuggest/UbersuggestSection7';
import TestimonialSection from '../sections/TestimonialSection';


const UberSuggest = ({data}) => {
  // console.log(data)
  const mainData = data.allWpPage.nodes[0].caseStudyUbersuggestPageACF;
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');

  useEffect(() => {
    let unmounted = false;
    gsap.registerPlugin(ScrollTrigger)
    setTimeout(() => {
      if (!unmounted) {
        gsap.set(sectionRef.current, {
          scrollTrigger: {
            trigger: '.ubersuggest-case-study .section-6',
            start: "top 60%",
            end: "bottom 60%",
            ease: "ease",
            scrub: true,
            markers: false,
            onEnter: () => setBgTransition('ubersuggest-bg'),
            onLeave: () => setBgTransition(''),
            onEnterBack: () => setBgTransition('ubersuggest-bg'),
            onLeaveBack: () => setBgTransition(''),
          }
        });
      }
    }, 1000);

    return () => {
      unmounted = true;
    }
  }, []);
  return (
    <Layout>
      <Seo title="Ubersuggest" />
      <div className={"ubersuggest-case-study page-wrapper " + bgTransition} id="ubersuggest-casestudy-page-wrapper" ref={sectionRef}>
          <UbersuggestHeroSection 
            preTitle={parse(mainData.ubersuggestHeroSectionPretitle)}
            mainTitle={parse(mainData.ubersuggestHeroSectionTitle)}
            image1x={
              {
                sourceUrl: mainData.ubersuggestHeroSectionImage1x.sourceUrl,
                altText: mainData.ubersuggestHeroSectionImage1x.altText,
                width: mainData.ubersuggestHeroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.ubersuggestHeroSectionImage2x.sourceUrl,
                altText: mainData.ubersuggestHeroSectionImage2x.altText,
                width: mainData.ubersuggestHeroSectionImage2x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.ubersuggestHeroSectionImage21x.sourceUrl,
                altText: mainData.ubersuggestHeroSectionImage21x.altText,
                width: mainData.ubersuggestHeroSectionImage21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.ubersuggestHeroSectionImage22x.sourceUrl,
                altText: mainData.ubersuggestHeroSectionImage22x.altText,
                width: mainData.ubersuggestHeroSectionImage22x.width,
              }
            }
          />
          <ChallengesSection
            companyInfoList={mainData.ubersuggestChallengeSectionCompanyInfoList}
            challengesList={mainData.ubersuggestChallengeSectionChallangesList}
          />
          <UbersuggestSection1 
            image1x={
            {
              sourceUrl: mainData.ubersuggestSection1Image1x.sourceUrl,
              altText: mainData.ubersuggestSection1Image1x.altText,
              width: mainData.ubersuggestSection1Image1x.width,
            }
            }
            image2x={
              {
                sourceUrl: mainData.ubersuggestSection1Image2x.sourceUrl,
                altText: mainData.ubersuggestSection1Image2x.altText,
                width: mainData.ubersuggestSection1Image2x.width,
              }
            }
            videoUrl={mainData.ubersuggestSection1VideoUrl}
          />
          <UbersuggestSection2 
            mainTitle={parse(mainData.ubersuggestSection2Title)}
            mainDescription={parse(mainData.ubersuggestSection2Description)}
            image11x={
            {
              sourceUrl: mainData.ubersuggestSection2Image11x.sourceUrl,
              altText: mainData.ubersuggestSection2Image11x.altText,
              width: mainData.ubersuggestSection2Image11x.width,
            }
            }
            image12x={
              {
                sourceUrl: mainData.ubersuggestSection2Image12x.sourceUrl,
                altText: mainData.ubersuggestSection2Image12x.altText,
                width: mainData.ubersuggestSection2Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.ubersuggestSection2Image21x.sourceUrl,
                altText: mainData.ubersuggestSection2Image21x.altText,
                width: mainData.ubersuggestSection2Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.ubersuggestSection2Image22x.sourceUrl,
                altText: mainData.ubersuggestSection2Image22x.altText,
                width: mainData.ubersuggestSection2Image22x.width,
              }
            }
            image31x={
            {
              sourceUrl: mainData.ubersuggestSection2Image31x.sourceUrl,
              altText: mainData.ubersuggestSection2Image31x.altText,
              width: mainData.ubersuggestSection2Image31x.width,
            }
            }
            image32x={
              {
                sourceUrl: mainData.ubersuggestSection2Image32x.sourceUrl,
                altText: mainData.ubersuggestSection2Image32x.altText,
                width: mainData.ubersuggestSection2Image32x.width,
              }
            }
            mainTitle1={parse(mainData.ubersuggestSection2Title2)}
            image41x={
              {
                sourceUrl: mainData.ubersuggestSection2Image41x.sourceUrl,
                altText: mainData.ubersuggestSection2Image41x.altText,
                width: mainData.ubersuggestSection2Image41x.width,
              }
            }
            image42x={
              {
                sourceUrl: mainData.ubersuggestSection2Image42x.sourceUrl,
                altText: mainData.ubersuggestSection2Image42x.altText,
                width: mainData.ubersuggestSection2Image42x.width,
              }
            }
            image51x={
              {
                sourceUrl: mainData.ubersuggestSection2Image51x.sourceUrl,
                altText: mainData.ubersuggestSection2Image51x.altText,
                width: mainData.ubersuggestSection2Image51x.width,
              }
            }
            image52x={
              {
                sourceUrl: mainData.ubersuggestSection2Image52x.sourceUrl,
                altText: mainData.ubersuggestSection2Image52x.altText,
                width: mainData.ubersuggestSection2Image52x.width,
              }
            }
          />
          <UbersuggestSection3 
            mainTitle={parse(mainData.ubersuggestSection3Title)}
            mainDescription={parse(mainData.ubersuggestSection3Description)}
            image1x={
              {
                sourceUrl: mainData.ubersuggestSection3Image1x.sourceUrl,
                altText: mainData.ubersuggestSection3Image1x.altText,
                width: mainData.ubersuggestSection3Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.ubersuggestSection3Image2x.sourceUrl,
                altText: mainData.ubersuggestSection3Image2x.altText,
                width: mainData.ubersuggestSection3Image2x.width,
              }
            }
            videoUrl={mainData.ubersuggestSection3VideoUrl}
          />
          <UbersuggestSection4 
            mainTitle={parse(mainData.ubersuggestSection4Title)}
            mainDescription={parse(mainData.ubersuggestSection4Description)}
            image1x={
              {
                sourceUrl: mainData.ubersuggestSection4Image1x.sourceUrl,
                altText: mainData.ubersuggestSection4Image1x.altText,
                width: mainData.ubersuggestSection4Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.ubersuggestSection4Image2x.sourceUrl,
                altText: mainData.ubersuggestSection4Image2x.altText,
                width: mainData.ubersuggestSection4Image2x.width,
              }
            }
          />
          <UbersuggestSection5 
            mainTitle={parse(mainData.ubersuggestSection5Title)}
            mainDescription={parse(mainData.ubersuggestSection5Description)}
            image11x={
              {
                sourceUrl: mainData.ubbersuggestSection5Image11x.sourceUrl,
                altText: mainData.ubbersuggestSection5Image11x.altText,
                width: mainData.ubbersuggestSection5Image11x.width,
              }
            }
            image12x={
              {
                sourceUrl: mainData.ubbersuggestSection5Image12x.sourceUrl,
                altText: mainData.ubbersuggestSection5Image12x.altText,
                width: mainData.ubbersuggestSection5Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.ubbersuggestSection5Image21x.sourceUrl,
                altText: mainData.ubbersuggestSection5Image21x.altText,
                width: mainData.ubbersuggestSection5Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.ubbersuggestSection5Image22x.sourceUrl,
                altText: mainData.ubbersuggestSection5Image22x.altText,
                width: mainData.ubbersuggestSection5Image22x.width,
              }
            }
            image31x={
              {
                sourceUrl: mainData.ubbersuggestSection5Image31x.sourceUrl,
                altText: mainData.ubbersuggestSection5Image31x.altText,
                width: mainData.ubbersuggestSection5Image31x.width,
              }
            }
            image32x={
              {
                sourceUrl: mainData.ubbersuggestSection5Image32x.sourceUrl,
                altText: mainData.ubbersuggestSection5Image32x.altText,
                width: mainData.ubbersuggestSection5Image32x.width,
              }
            }
          />
          <UbersuggestSection6 
            mainTitle={parse(mainData.ubersuggestSection6Title)}
            mainDescription={parse(mainData.ubersuggestSection6Description)}
            image1x={
              {
                sourceUrl: mainData.ubersuggestSection6Image1x.sourceUrl,
                altText: mainData.ubersuggestSection6Image1x.altText,
                width: mainData.ubersuggestSection6Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.ubersuggestSection6Image2x.sourceUrl,
                altText: mainData.ubersuggestSection6Image2x.altText,
                width: mainData.ubersuggestSection6Image2x.width,
              }
            }
          />
          <UbersuggestSection7 
            mainTitle={parse(mainData.ubersuggestSection7Title)}
            image1x={
              {
                sourceUrl: mainData.ubersuggestSection7Image1x.sourceUrl,
                altText: mainData.ubersuggestSection7Image1x.altText,
                width: mainData.ubersuggestSection7Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.ubersuggestSection7Image2x.sourceUrl,
                altText: mainData.ubersuggestSection7Image2x.altText,
                width: mainData.ubersuggestSection7Image2x.width,
              }
            }
          />
          <TestimonialSection
            extraClasses=""
            mainTitle={parse(mainData.ubersuggestTestimonialSectionTitle)}
            message={parse(mainData.ubersuggestTestimonialSectionDescription)}
            name={parse(mainData.ubersuggestTestimonialSectionName)}
            designation={parse(mainData.ubersuggestTestimonialSectionDesignation)}
            headshot1x={
              {
                sourceUrl: mainData.ubersuggestTestimonialSectionHeadshot1x.sourceUrl,
                altText: mainData.ubersuggestTestimonialSectionHeadshot1x.altText,
                width: mainData.ubersuggestTestimonialSectionHeadshot1x.width,
              }
            }
            headshot2x={
              {
                sourceUrl: mainData.ubersuggestTestimonialSectionHeadshot2x.sourceUrl,
                altText: mainData.ubersuggestTestimonialSectionHeadshot2x.altText,
                width: mainData.ubersuggestTestimonialSectionHeadshot2x.width,
              }
            }
            logo={
              {
                sourceUrl: mainData.ubersuggestTestimonialSectionLogo.sourceUrl,
                altText: mainData.ubersuggestTestimonialSectionLogo.altText,
                width: mainData.ubersuggestTestimonialSectionLogo.width,
              }
            }
        />
      </div>
    </Layout>
  )
}

export const UbersuggestQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "ubersuggest"}}) {
    nodes {
      caseStudyUbersuggestPageACF {
        ubersuggestHeroSectionPretitle
        ubersuggestHeroSectionTitle
        ubersuggestHeroSectionImage1x {
          altText
          sourceUrl
          width
        }
        ubersuggestHeroSectionImage2x {
          altText
          sourceUrl
          width
        }
        ubersuggestHeroSectionImage21x {
          altText
          sourceUrl
          width
        }
        ubersuggestHeroSectionImage22x {
          altText
          sourceUrl
          width
        }
        ubersuggestChallengeSectionCompanyInfoList {
          title
          description
        }
        ubersuggestChallengeSectionChallangesList {
          title
          description
        }
        ubersuggestSection1VideoUrl
        ubersuggestSection1Image1x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection1Image2x {
          altText
          sourceUrl
          width
        }
        
        ubersuggestSection2Title
        ubersuggestSection2Description
        ubersuggestSection2Image11x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image12x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image21x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image22x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image31x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image32x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Title2
        ubersuggestSection2Image41x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image42x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image51x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection2Image52x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection3Title
        ubersuggestSection3Description
        ubersuggestSection3Image1x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection3Image2x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection3VideoUrl
        ubersuggestSection4Title
        ubersuggestSection4Description
        ubersuggestSection4Image1x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection4Image2x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection5Title
        ubersuggestSection5Description
        ubbersuggestSection5Image11x {
          altText
          sourceUrl
          width
        }
        ubbersuggestSection5Image12x {
          altText
          sourceUrl
          width
        }
        ubbersuggestSection5Image21x {
          altText
          sourceUrl
          width
        }
        ubbersuggestSection5Image22x {
          altText
          sourceUrl
          width
        }
        ubbersuggestSection5Image31x {
          altText
          sourceUrl
          width
        }
        ubbersuggestSection5Image32x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection6Title
        ubersuggestSection6Description
        ubersuggestSection6Image1x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection6Image2x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection7Title
        ubersuggestSection7Image1x {
          altText
          sourceUrl
          width
        }
        ubersuggestSection7Image2x {
          altText
          sourceUrl
          width
        }
        ubersuggestTestimonialSectionTitle
        ubersuggestTestimonialSectionDescription
        ubersuggestTestimonialSectionHeadshot1x {
          altText
          sourceUrl
          width
        }
        ubersuggestTestimonialSectionHeadshot2x {
          altText
          sourceUrl
          width
        }
        ubersuggestTestimonialSectionName
        ubersuggestTestimonialSectionDesignation
        ubersuggestTestimonialSectionLogo {
          altText
          sourceUrl
          width
        }
      }
    }
  }
} 
`

export default UberSuggest
